import { graphql, PageProps } from "gatsby";
import React, {
  createContext,
  Fragment,
  useEffect,
  useRef,
  useState,
} from "react";

import { Carousel } from "../components/homepage/carousel/Carousel.component";
import { Layout } from "../components/layout/Layout.component";
import HomepageSection from "../components/homepage/homepage-section/HomepageSection.component";
import { CarouselItem } from "../components/navigation/CarouselNav.component";
import { HomepageSectionData } from "../components/homepage/homepage-section/homepage-section.types";

import {
  AllContentfulContentBlocks,
  ContentfulTypenames,
} from "../shared/contentful/contentful.types";
import { PageContextWithNav, PageData } from "../shared/page.types";

import "../components/homepage/homepage.scss";

type IndexPageProps = {
  pageData: PageData<AllContentfulContentBlocks> & { introClips: any[] };
};

export type IndexSectionProps = {
  showTitle: boolean;
  title: string | undefined;
};

type NavData = {
  label: string;
  slug: string;
};

export const HomepageContext = createContext<PageContextWithNav>({
  title: "",
  nav: [],
});

const IndexPage = (props: PageProps<IndexPageProps>) => {
  const {
    data,
    location: { href },
  } = props;

  if (!data) return null;

  const { pageData } = data;

  const [navigationParts, setNavigationParts] = useState<NavData[]>([]);
  const [carouselNavigation, setCarouselNavigation] = useState<CarouselItem[]>(
    []
  );

  const { introClips, intro, seo } = pageData;
  const introContent = { introClips, intro };

  const allHomepageContentSection = pageData.content.filter(
    ({ __typename }) => __typename === ContentfulTypenames.HomepageSection
  ) as HomepageSectionData[];

  let allRefs: any[] = [];

  if (allRefs.length === 0) {
    allRefs = allHomepageContentSection.map((section) => {
      const sectionRef = useRef<HTMLDivElement>(null);
      return { ref: sectionRef, slug: section.sectionId };
    });
  }

  useEffect(() => {
    if (allRefs && navigationParts.length === 0) {
      const content = allHomepageContentSection.map((section) => {
        return {
          label: section.sectionTitle,
          slug: section.sectionId,
        };
      });
      setNavigationParts(content);
    }
  }, [allRefs]);

  useEffect(() => {
    if (navigationParts) {
      const finalNavContent = navigationParts.map(({ label, slug }) => {
        return {
          label,
          slug,
          ref: allRefs?.find(({ slug: refSlug }) => refSlug === slug)?.ref,
        };
      });
      setCarouselNavigation(finalNavContent);
    }
  }, [navigationParts]);

  const seoProps = {
    ...seo,
    url: href,
  };

  if (carouselNavigation.length === 0) {
    return null;
  }

  return (
    <HomepageContext.Provider
      value={{ nav: carouselNavigation, title: "homepage" }}
    >
      <Layout
        pageNavigation={Carousel}
        introContent={introContent}
        seo={seoProps}
      >
        {allHomepageContentSection.map((section, index) => {
          const { sectionId } = section;
          const sectionRef = carouselNavigation?.find(
            ({ slug }) => slug === sectionId
          )?.ref;
          if (!sectionRef) return null;

          return (
            <Fragment key={sectionId}>
              <div
                className="section-index"
                ref={sectionRef}
                id={sectionId}
                data-active={index}
              />
              <HomepageSection data={section} />
            </Fragment>
          );
        })}
      </Layout>
    </HomepageContext.Provider>
  );
};

export default IndexPage;

export const query = graphql`
  query newhomepagePageQuery($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    pageData: contentfulLayoutHomepage(
      identifier: { eq: "homepage" }
      node_locale: { eq: $language }
    ) {
      introClips {
        ...video
      }
      intro {
        raw
      }
      content {
        ...homepageSection
      }
      seo {
        ...seo
      }
    }
  }
`;
