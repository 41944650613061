import { useEffect, useState } from "react";

export const useScroll = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window?.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    if (window) {
      window?.addEventListener("scroll", handleScroll, { passive: true });
    }

    return () => {
      if (window) {
        window?.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return { scrollPosition };
};
