import React from "react";

import {
  AnyContentfulBlock,
  ContentfulImage,
  ContentfulTypenames,
  HomepageExpertiseData,
} from "../../shared/contentful/contentful.types";

import { HomepageSectionData } from "./homepage-section/homepage-section.types";
import HomepageSection from "./homepage-section/HomepageSection.component";
import ExpertiseCard from "./expertise-card/ExpertiseCard.component";
import PortfolioTiles from "./portfolio/PortfolioTiles.component";
import { PortfolioTilesData } from "./portfolio/portfolio-tiles.types";
import CallToAction from "../call-to-action/CallToAction.component";
import { CallToActionData } from "../call-to-action/call-to-action.types";
import RichText from "../rich-text/RichText.component";
import { RichTextData } from "../rich-text/rich-text.types";
import CustomImage from "../custom-image/CustomImage.component";
import JobOffer from "./job-offer/JobOffer.component";
import { JobOfferCflData } from "./job-offer/job-offer.type";
import RecollectionsGrid from "./recollections-grid/RecollectionsGrid.component";
import { RecollectionsGridData } from "./recollections-grid/recollections-grid.types";

export type ClassNamesOptions = Record<Partial<ContentfulTypenames>, string>;

export type CflComponentProps<Data> = {
  data: Data;
  className?: string;
};

export const returnComponent = ({
  item,
  classNames,
  index,
}: {
  item: AnyContentfulBlock;
  classNames?: ClassNamesOptions;
  index: number;
}) => {
  const itemClassName = classNames?.[item.__typename];

  switch (item.__typename) {
    case ContentfulTypenames.HomepageSection:
      return (
        <HomepageSection
          key={index}
          data={item as HomepageSectionData}
          className={itemClassName}
        />
      );
    case ContentfulTypenames.HomepageExpertise:
      return (
        <ExpertiseCard
          key={index}
          data={item as HomepageExpertiseData}
          className={itemClassName}
        />
      );
    case ContentfulTypenames.PortfolioTiles:
      return (
        <PortfolioTiles
          key={index}
          data={item as PortfolioTilesData}
          className={itemClassName}
        />
      );
    case ContentfulTypenames.CallToAction:
      return (
        <CallToAction
          key={index}
          data={item as CallToActionData}
          className={itemClassName}
        />
      );
    case ContentfulTypenames.Richtext:
      return (
        <RichText
          key={index}
          data={item as RichTextData}
          className={itemClassName}
        />
      );
    case ContentfulTypenames.Image:
      return (
        <CustomImage
          key={index}
          data={item as ContentfulImage}
          className={itemClassName}
        />
      );
    case ContentfulTypenames.JobOffer:
      return (
        <JobOffer
          key={index}
          data={item as JobOfferCflData}
          className={itemClassName}
        />
      );
    case ContentfulTypenames.RecollectionGrid:
      return (
        <RecollectionsGrid key={index} data={item as RecollectionsGridData} />
      );
    default:
      <></>;
  }
};
export const transformHomepageContentfulBlocks = ({
  items,
  classNames,
}: {
  items: AnyContentfulBlock[];
  classNames?: ClassNamesOptions;
}) => {
  return items.map((item, index) =>
    returnComponent({ item, classNames, index })
  );
};
