import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS } from "@contentful/rich-text-types";
import { Options } from "@contentful/rich-text-react-renderer";
import React, { ReactNode } from "react";

import { CflComponentProps } from "../homepage/homepage.render";

import { RichTextData } from "./rich-text.types";

import "./rich-text.scss";

type Props = CflComponentProps<RichTextData>;

const renderOptions: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_node: ReactNode, children: ReactNode) => (
      <p>{children}</p>
    ),
  },
};

const RichText = ({ data, className = "" }: Props) => {
  return (
    <div className={`rich-text__wrapper${className ? " " + className : ""}`}>
      {data.body && renderRichText(data.body, renderOptions)}
    </div>
  );
};
export default RichText;
