import React from "react";
import { RecollectionGrid } from "../../../shared/contentful/contentful.types";
import RecollectionCard from "../../recollections/recollection-card/RecollectionCard.component";
import { CflComponentProps } from "../homepage.render";

import "./recollections-grid.scss";

type Props = CflComponentProps<RecollectionGrid>;

const RecollectionsGrid = ({ data, className = "" }: Props) => {
  if (!data) return null;
  const { recollections } = data;

  if (recollections?.length === 0) return null;

  return (
    <div
      className={`recollections-grid__wrapper${
        className ? " " + className : ""
      }`}
    >
      {recollections?.map((recollection, index) => (
        <RecollectionCard key={index} {...recollection} />
      ))}
    </div>
  );
};
export default RecollectionsGrid;
