import React, { ReactNode } from "react";
import { Link, useI18next } from "gatsby-plugin-react-i18next";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS } from "@contentful/rich-text-types";
import { Options } from "@contentful/rich-text-react-renderer";

import CallToAction from "../../call-to-action/CallToAction.component";

import { HomepageExpertiseData } from "../../../shared/contentful/contentful.types";
import { slugifyTitle } from "../../../shared/utils/slug.utils";

import "./expertise-card.scss";
import { CflComponentProps } from "../homepage.render";

type Props = CflComponentProps<HomepageExpertiseData>;

const renderOptions: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_node: ReactNode, children: ReactNode) => (
      <p className={"expertise-card__card-content"}>{children}</p>
    ),
  },
};

const ExpertiseCard = ({ data, className = "" }: Props) => {
  const { language: locale } = useI18next();
  const { title, assets, callToAction: cta, expertiseContent: content } = data;
  const image = getImage(assets?.[0]?.media as any);

  const sectionLink = `/services#${slugifyTitle(title)}`;

  return (
    <div
      className={`expertise-card__wrapper${className ? " " + className : ""}`}
    >
      <Link to={sectionLink} language={locale}>
        {image && <GatsbyImage image={image} alt={"image"} />}
        <div>
          <h2 className={"expertise-card__title"}>{title}</h2>
          {renderRichText(content, renderOptions)}
        </div>
      </Link>
      <CallToAction
        data={{ ...cta, link: sectionLink }}
        className="homepage-section__learn-more-cta"
      />
    </div>
  );
};
export default ExpertiseCard;
