import React, { memo } from "react";
import ReactDOM from "react-dom";
import { X } from "react-feather";
import { useResponsive } from "../../hooks/useResponsive";
import { ContentfulAssetVideo } from "../../shared/contentful/contentful.types";
import { EmbedVideo } from "./embed/EmbedVideo.component";
import { NativeVideo } from "./native/NativeVideo.component";

import "./video-player.scss";

export type VideoPlayerProps = {
  closeVideo: () => void;
  title?: string;
  asset?: ContentfulAssetVideo;
  description?: string;
  videoUrl?: string;
};

const VideoPlayer = ({
  asset,
  title,
  description,
  closeVideo,
  videoUrl,
}: VideoPlayerProps) => {
  const { isMedium } = useResponsive();
  return (
    <>
      {ReactDOM.createPortal(
        <aside className={"video-player__wrapper"}>
          <button
            onClick={closeVideo}
            aria-label={"close/fermer"}
            className={"video-player__close-icon"}
          >
            <X color={"white"} size={isMedium ? 48 : 24} />
          </button>
          {asset ? (
            <NativeVideo
              asset={asset}
              title={title}
              description={description}
            />
          ) : (
            <EmbedVideo
              videoUrl={videoUrl}
              title={title}
              description={description}
            />
          )}
        </aside>,
        document.body
      )}
    </>
  );
};

export default memo(VideoPlayer);
