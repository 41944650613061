import React from "react";
import { Link, useI18next } from "gatsby-plugin-react-i18next";

import { HomepageSectionData } from "./homepage-section.types";
import {
  CflComponentProps,
  transformHomepageContentfulBlocks,
} from "../homepage.render";
import { ContentfulTypenames } from "../../../shared/contentful/contentful.types";

import "./homepage-section.scss";

type Props = CflComponentProps<HomepageSectionData>;

const classNamesOptions = {
  [ContentfulTypenames.CallToAction]: "homepage-section__learn-more-cta",
  [ContentfulTypenames.Richtext]: "homepage-section__rich-text-content",
  [ContentfulTypenames.PortfolioTiles]: "homepage-section__portfolio-tiles",
};

const HomepageSection = ({ data }: Props) => {
  if (!data) return null;
  const { language } = useI18next();

  const { sectionTitle, fullwidthOnMobile, pageLink, sectionContent } = data;

  return (
    <div
      className={`homepage-section__wrapper ${
        fullwidthOnMobile ? "fullwidth" : "boxed"
      }`}
    >
      <p
        className={`homepage-section__title ${
          fullwidthOnMobile && " ml-layout sm:no-m-layout"
        }`}
      >
        {pageLink ? (
          <Link language={language} to={`/${pageLink}`}>
            {sectionTitle}
          </Link>
        ) : (
          <span>{sectionTitle}</span>
        )}
      </p>
      <div className="homepage-section__content">
        {transformHomepageContentfulBlocks({
          items: sectionContent,
          classNames: classNamesOptions as any,
        })}
      </div>
    </div>
  );
};
export default HomepageSection;
