import React from "react";
import { ArrowRight } from "react-feather";
import { Trans } from "react-i18next";
import { RecollectionArticle } from "../../shared/contentful/contentful.types";

import "./recollections.scss";

export type ArticleProps = Omit<RecollectionArticle, "__typename">;

const AsArticle = (props: ArticleProps) => {
  const {
    title,
    excerpt: { excerpt },
    url,
    date,
    mediaName,
  } = props;

  const displayFooter = date && mediaName;

  return (
    <div className={"article-wrapper"}>
      <p className={"article-title"}>{title}</p>
      <p className="article-excerpt">{excerpt}</p>
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className={"link-icon read-more"}
        aria-label={"lire tout l'article"}
      >
        <ArrowRight size={16} />
        <span>
          <Trans>recollectionReadMore</Trans>
        </span>
      </a>
      {displayFooter && (
        <div className="article-footer">
          <p>{date}</p>
          <p>{mediaName}</p>
        </div>
      )}
    </div>
  );
};
export default AsArticle;
