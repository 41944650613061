import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby-plugin-react-i18next";
import React, { Fragment } from "react";
import { ArrowRight } from "react-feather";

import { CflComponentProps } from "../homepage.render";

import { PortfolioTilesData } from "./portfolio-tiles.types";

import "./portfolio-tiles.scss";

type Props = CflComponentProps<PortfolioTilesData>;

const Overlay = ({
  ctaContent,
  slug,
}: {
  ctaContent: string;
  slug: string;
}) => (
  <Link to={`/${slug}`} className="portfolio-tiles__overlay">
    <span>
      <ArrowRight size={32} />
      {ctaContent}
    </span>
  </Link>
);

const PortfolioTiles = ({ data, className = "" }: Props) => {
  if (!data) return null;

  const { images, portfolioPageSlug, callToActionLabel } = data;

  return (
    <div
      className={`portfolio-tiles__wrapper${className ? " " + className : ""}`}
    >
      {portfolioPageSlug && (
        <Overlay ctaContent={callToActionLabel} slug={portfolioPageSlug} />
      )}
      {images.map((t, i) => {
        const image = getImage(t.gatsbyImageData);
        return (
          <Fragment key={i}>
            {image && <GatsbyImage image={image} alt={t.description} />}
          </Fragment>
        );
      })}
    </div>
  );
};
export default PortfolioTiles;
