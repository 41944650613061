export const slugifyTitle = (title: string) => {
  if (!title) {
    throw new Error("title must be defined");
  }
  return title
    .replace(/(é|è|ê)/gim, "e")
    .replace(/à/gim, "a")
    .replace(/\W+/gim, "-")
    .replace(/\W$/gm, "")
    .toLowerCase();
};
