import { Link, useI18next } from "gatsby-plugin-react-i18next";
import React from "react";
import { ArrowRight } from "react-feather";

import { CflComponentProps } from "../homepage/homepage.render";

import { CallToActionData } from "./call-to-action.types";

import "./call-to-action.scss";

type Props = CflComponentProps<CallToActionData>;

const InternalLink = ({ label, linkTo }: { label: string; linkTo: string }) => {
  const { language: locale } = useI18next();

  const finalLink = linkTo.startsWith("/") ? linkTo : "/" + linkTo;

  return (
    <Link
      to={finalLink}
      language={locale}
      className={"call-to-action__link-icon"}
    >
      <ArrowRight />
      {label}
    </Link>
  );
};

const CallToAction = ({ data, className = "" }: Props) => {
  if (!data) return null;

  const { ctaContent, externalLink, link } = data;

  return (
    <div
      className={`call-to-action__wrapper${className ? " " + className : ""}`}
    >
      {externalLink && (
        <a
          href={externalLink}
          target="_blank"
          rel="noopener noreferrer"
          className={"call-to-action__link-icon"}
        >
          <ArrowRight />
          {ctaContent}
        </a>
      )}
      {link && <InternalLink label={ctaContent} linkTo={link} />}
    </div>
  );
};
export default CallToAction;
