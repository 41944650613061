import React from "react";
import { CflComponentProps } from "../homepage.render";

import { JobOfferCflData } from "./job-offer.type";

import "./job-offer.scss";

type Props = CflComponentProps<JobOfferCflData>;

const JobOffer = ({ data, className = "" }: Props) => {
  if (!data || data?.jobTitle.match("--")) return null;

  const { jobTitle, positionType, jobDescription, url } = data;

  return (
    <a
      href={url || ""}
      target="_blank"
      rel="noopener noreferrer"
      className={`job-offer__wrapper${className ? " " + className : ""}`}
    >
      {positionType && (
        <p className="job-offer__position-type">{positionType}</p>
      )}
      <p className="job-offer__title">{jobTitle}</p>
      {jobDescription.jobDescription && (
        <p className="job-offer__description">
          {jobDescription.jobDescription}
        </p>
      )}
    </a>
  );
};
export default JobOffer;
