import React from "react";
import {
  ContentfulTypenames,
  RecollectionBlock,
} from "../../../shared/contentful/contentful.types";

import "../recollections.scss";
import AsArticle, { ArticleProps } from "../AsArticle.component";
import AsVideo, { AsVideoProps } from "../AsVideo.component";

const RecollectionCard = (props: RecollectionBlock) => {
  const {
    asset: { __typename, ...assetRest },
    __typename: _assetType,
  } = props;

  switch (__typename) {
    case ContentfulTypenames.Video:
      return <AsVideo {...(assetRest as AsVideoProps)} />;
    case ContentfulTypenames.RecollectionArticle:
      return <AsArticle {...(assetRest as ArticleProps)} />;
    default:
      return null;
  }
};

export default RecollectionCard;
