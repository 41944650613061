import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { memo, useContext, useState } from "react";
import { ContentfulVideo } from "../../shared/contentful/contentful.types";
import { PageContext, TPageContext } from "../layout/PageLayout.component";
import VideoPlayer from "../videos/VideoPlayer.component";

import "./recollections.scss";

export type AsVideoProps = Omit<ContentfulVideo, "__typename">;

const AsVideo = (props: AsVideoProps) => {
  const { asset, thumbnail, title, description, videoUrl } = props;

  const image = getImage(thumbnail?.media as any);
  const alt = `${thumbnail?.media?.title} - ${thumbnail?.media.description}`;

  const [displayVideo, setDisplayVideo] = useState(false);

  const { toggleScroll } = useContext(PageContext) as TPageContext;

  const openVideo = () => {
    setDisplayVideo(true);
    toggleScroll();
  };

  const closeVideo = () => {
    setDisplayVideo(false);
    toggleScroll();
  };

  return (
    <>
      <button onClick={openVideo} className={"recollection-cover-wrapper"}>
        {image && <GatsbyImage image={image} alt={alt} />}
      </button>
      {displayVideo && (
        <VideoPlayer
          videoUrl={videoUrl}
          asset={asset}
          description={description}
          title={title}
          closeVideo={closeVideo}
        />
      )}
    </>
  );
};

export default memo(AsVideo);
