import React, { useEffect, useRef, useState } from "react";
import { useScroll } from "../../hooks/useScroll";

import "./carousel.scss";

export type CarouselItem = {
  label: string;
  slug: string;
  ref: React.RefObject<HTMLDivElement> | undefined;
};

type Props = {
  nav: CarouselItem[];
};

type ItemProps = {
  item: CarouselItem;
  activeSection: number;
  scrollTo: (offset: number) => void;
  showLast: boolean;
};

const PADDING_VALUE = 16;

const Item = ({
  item: { label, ref, slug },
  activeSection,
  scrollTo,
  showLast,
}: ItemProps) => {
  const [isActive, setIsActive] = useState(false);
  const itemRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    activeSection === parseInt(ref?.current?.dataset.active || "0")
      ? setIsActive(true)
      : setIsActive(false);
    return () => {
      setIsActive(false);
    };
  }, [activeSection]);

  useEffect(() => {
    if (isActive) {
      scrollTo(itemRef.current?.offsetLeft || 0);
    }
  }, [isActive]);

  return (
    <>
      <hr className={`carousel-divider ${isActive ? "active" : ""}`} />
      <li
        key={label}
        className={`item ${isActive ? "active" : ""}`}
        ref={itemRef}
      >
        <a href={`#${slug}`}>{label}</a>
      </li>
      {showLast && <hr className="carousel-divider" />}
    </>
  );
};

const CarouselNav = ({ nav }: Props) => {
  const [activeSection, setActiveSection] = useState(0);

  const navRef = useRef<HTMLUListElement>(null);

  const { scrollPosition } = useScroll();

  useEffect(() => {
    nav?.forEach(({ ref }, index) => {
      if (!ref?.current || typeof window === "undefined") {
        return;
      }
      const offsetTrigger = window.innerHeight / 2;
      const nextRef = nav[index + 1];

      const treshold = [
        ref.current.offsetTop - offsetTrigger,
        nextRef ? nextRef.ref?.current?.offsetTop : document.body.clientHeight,
      ];

      if (!treshold || !treshold[0] || !treshold[1]) {
        return;
      }

      if (scrollPosition > treshold[0] && scrollPosition < treshold[1]) {
        const active = parseInt(ref.current.dataset.active || "0");

        setActiveSection(active);
      }
    });
  }, [scrollPosition]);

  const scrollTo = (offset: number) => {
    if (navRef?.current) {
      navRef.current.scrollTo({
        left: offset - PADDING_VALUE,
        behavior: "smooth",
      });
    }
  };

  if (!nav) {
    return null;
  }

  return (
    <div className={"carousel-wrapper"}>
      <ul className={"carousel-content"} ref={navRef}>
        {nav?.map((n, i) => {
          return (
            <Item
              key={`${n.label}-${i}`}
              item={n}
              activeSection={activeSection}
              scrollTo={scrollTo}
              showLast={i + 1 === nav.length}
            />
          );
        })}
      </ul>
    </div>
  );
};
export default CarouselNav;
