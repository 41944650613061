import { IGatsbyImageData } from "gatsby-plugin-image";

import { HomepageSectionData } from "../../components/homepage/homepage-section/homepage-section.types";
import { PortfolioTilesData } from "../../components/homepage/portfolio/portfolio-tiles.types";
import { CallToActionData } from "../../components/call-to-action/call-to-action.types";
import { RichTextData } from "../../components/rich-text/rich-text.types";
import { JobOfferCflData } from "../../components/homepage/job-offer/job-offer.type";
import { RecollectionsGridData } from "../../components/homepage/recollections-grid/recollections-grid.types";

export enum ContentfulTypenames {
  HomepageSection = "ContentfulHomepageSectionContent",
  HomepageExpertise = "ContentfulComponentExpertise",
  PortfolioTiles = "ContentfulHomepagePortfolioTiles",
  CallToAction = "ContentfulComponentCallToAction",
  Richtext = "ContentfulComponentRichText",
  SocialMedia = "ContentfulSocialMedia",
  Image = "ContentfulComponentImage",
  JobOffer = "ContentfulComponentJobOffer",
  Video = "ContentfulVideo",
  MediaVideo = "ContentfulVideo",
  RecollectionGrid = "ContentfulRecollectionGrid",
  RecollectionArticle = "ContentfulRecollectionArticle",
  ContentBlock = "ContentfulContentBlock",
  Project = "ContentfulProject",
}

export type ServiceContent = {
  intro: JsonContent;
  identifier: string;
  title: string;
  thumbnail: ContentfulAssetImage;
  content: ContentBlock<ContentfulImage>[];
};

export type ContentfulAssetImage = {
  __typename: ContentfulTypenames;
  title: string;
  description: string;
  gatsbyImageData: IGatsbyImageData;
};

export type ContentfulImage = {
  __typename: ContentfulTypenames;
  title?: string;
  media: {
    description: string;
    gatsbyImageData: IGatsbyImageData;
    title?: string;
  };
};

export type ContentfulAssetVideo = {
  __typename: ContentfulTypenames;
  id: string;
  title: string | null;
  description: string | null;
  file: { contentType: string; fileName: string; url: string };
};

export type ContentfulVideo = {
  __typename: ContentfulTypenames;
  title: string;
  description?: string;
  videoUrl?: string;
  asset?: ContentfulAssetVideo;
  thumbnail?: ContentfulImage;
};

export type ContentfulFile = {
  description: string;
  title?: string;
  file: { url: string };
};

export type ContentfulSvgImage = {
  __typename: ContentfulTypenames;
  media: ContentfulFile;
};

export type RecollectionArticle = {
  __typename: ContentfulTypenames;
  title: string;
  excerpt: { excerpt: string };
  url: string;
  mediaName?: string;
  date?: string;
};

export type RecollectionBlock<
  T = ContentfulImage | ContentfulVideo | RecollectionArticle
> = {
  __typename: ContentfulTypenames;
  asset: T;
};

export type JsonContent = {
  raw: string;
  references: any[]; // needed to match the contentful typing
};

export type VdeoClip = {
  name: string;
  media: any;
};

export type ContentBlock<T = any> = {
  __typename: ContentfulTypenames;
  content: JsonContent;
  title?: string;
  assets?: T[];
  pageID?: string;
};

export type PortfolioTiles = {
  __typename: ContentfulTypenames;
  images: ContentfulAssetImage[];
};

// export type HomepageSectionTitle = {
//   __typename: ContentfulTypenames;
//   title: string;
//   pageID: string;
// };

export type HomepageExpertiseData = {
  __typename: ContentfulTypenames;
  title: string;
  assets: ContentfulImage[];
  expertiseContent: JsonContent;
  callToAction: CallToActionData;
};

export type Expertise = {
  title: ContentfulTypenames;
  thumbnail: ContentfulImage;
  content: ContentBlock[];
  intro: JsonContent;
  seoTitle: string;
  seoDescription: string;
};

export type ContentfulSeo = {
  __typename: ContentfulTypenames;
  siteTitle: string;
  siteDescription: {
    siteDescription: string;
  };
};

export type SocialMedia = {
  __typename: ContentfulTypenames;
  label: string;
  url: string;
  icon: string;
};

export type AnyContentfulBlock =
  | RecollectionBlock
  | CallToActionData
  | HomepageExpertiseData
  | ContentBlock
  | ContentfulImage
  | PortfolioTiles
  | RecollectionGrid
  | HomepageSectionData
  | PortfolioTilesData
  | RichTextData
  | JobOfferCflData
  | RecollectionsGridData;

export type AllContentfulContentBlocks = AnyContentfulBlock[];

export type ContentfulInput = {
  name: string;
  label: string;
  inputType: string;
  isRequired: boolean;
};

export type Form = {
  name: string;
  fields: ContentfulInput[];
  buttonLabel: string;
};

export type Project = {
  __typename: ContentfulTypenames;
  title: string;
  client: string | null;
  developer: string | null;
  publisher: string | null;
  recognitions: JsonContent | null;
  gameAudio: string[] | null;
  animation: string[] | null;
  cinematics: string[] | null;
  motionCapture: string[] | null;
  talentManagement: string[] | null;
  thumbnail: ContentfulAssetImage;
};

export type RecollectionGrid = {
  __typename: ContentfulTypenames;
  recollections: RecollectionBlock[];
};
