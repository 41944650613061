import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import { CflComponentProps } from "../homepage/homepage.render";
import { ContentfulImage } from "../../shared/contentful/contentful.types";

import "./custom-image.scss";

type Props = CflComponentProps<ContentfulImage>;

const CustomImage = ({ data, className = "" }: Props) => {
  if (!data) return null;
  const { media } = data;
  const image = getImage(media as any);

  if (!image) return null;

  return (
    <div className={`custom-image__wrapper${className ? " " + className : ""}`}>
      <GatsbyImage image={image} alt={media.description} />
    </div>
  );
};
export default CustomImage;
